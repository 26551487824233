import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Category';
import MapIcon from '@material-ui/icons/MyLocation';
import DescriptionIcon from '@material-ui/icons/Description';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import { useApp } from '@backstage/core-plugin-api';
import * as icons from '@material-ui/icons';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull, { useLogoStyles } from './LogoFull';
import LogoIcon, { useIconStyles } from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { SiAmazons3 } from 'react-icons/si';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import { ExternalAppsConfig } from '../external-apps/ExternalConfig';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

export const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
  const defaultLogoStyles = useLogoStyles();
  const defaultIconStyles = useIconStyles();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull classes={defaultLogoStyles} /> : <LogoIcon classes={defaultIconStyles} />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="" text="Home" />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              title="APIs"
              to="catalog?filters[kind]=api"
              icon={useApp().getSystemIcon('kind:api')}
            />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component"
              icon={useApp().getSystemIcon('kind:component')}
            />
            <SidebarSubmenuItem
              title="Endpoints"
              to="catalog?filters[kind]=endpoint"
              icon={icons.ArrowForward}
            />
            <SidebarSubmenuItem
              title="Groups"
              to="catalog?filters[kind]=group"
              icon={useApp().getSystemIcon('kind:group')}
            />
            <SidebarSubmenuItem
              title="Locations"
              to="catalog?filters[kind]=location"
              icon={useApp().getSystemIcon('kind:location')}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system"
              icon={useApp().getSystemIcon('kind:system')}
            />
            <SidebarSubmenuItem
              title="Templates"
              to="catalog?filters[kind]=template"
              icon={useApp().getSystemIcon('kind:template')}
            />
            <SidebarSubmenuItem
              title="Users"
              to="catalog?filters[kind]=user"
              icon={useApp().getSystemIcon('kind:user')}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={icons.ArrowForward} to="catalog?filters%5Bkind%5D=endpoint&filters%5Buser%5D=all" text="Endpoints" />
        <SidebarItem icon={icons.EmojiEvents} to="catalog?filters%5Bkind%5D=leaderboard&filters%5Buser%5D=all" text="Progress" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Documents">
          <SidebarSubmenu title="Documents">
            <SidebarSubmenuItem
              title="ADRs"
              to="catalog/default/component/ADR/adrs"
              icon={icons.Compare}
            />
            <SidebarSubmenuItem
              title="Guides"
              to="docs/default/Component/Guides"
              icon={icons.Note}
            />
            <SidebarSubmenuItem
              title="Learnings"
              to="docs/default/Component/Learnings"
              icon={icons.EmojiObjects}
            />
            <SidebarSubmenuItem
              title="Sequence Diagrams"
              to="docs/default/Component/usecases"
              icon={LibraryBooks}
            />
            <SidebarSubmenuItem
              title="Technical Docs"
              to="docs/default/Component/technical-documents"
              icon={DescriptionIcon}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={icons.Note} to="docs/default/Component/Guides" text="Guides" />
        <SidebarItem icon={icons.Apps} to="/catalog?filters%5Bkind%5D=console&filters%5Buser%5D=all" text="Consoles">
          <SidebarSubmenu title="Consoles">
            <SidebarSubmenuItem
              title="Operational"
              to="/catalog?filters%5Bkind%5D=console&filters%5Btype%5D=ops-console&filters%5Buser%5D=all"
              icon={icons.Compare}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={MapIcon} to="tech-radar" text="Radars">
          <SidebarSubmenu title="Radars">
            <SidebarSubmenuItem
              title="Tech Radar"
              to="tech-radar"
              icon={icons.Compare}
            />
            <SidebarSubmenuItem
              title="Functional Radar"
              to="functional-radar"
              icon={icons.Note}
            />
            <SidebarSubmenuItem
              title="Digital Radar"
              to="digital-radar"
              icon={icons.EmojiObjects}
            />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={icons.Autorenew} to="" text="Integrations">
          <SidebarSubmenu title="Integrations">
            {Object.entries(ExternalAppsConfig).map(([key, value]) => (
              <SidebarItem key={key} icon={value.icon} to={value.path} target={value.target} text={value.text} />
            ))}
            <SidebarItem icon={SiAmazons3} to="document-viewer" text="Document Viewer" />
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={icons.DeveloperMode} to="/simulator" text="Simulator" target='_blank' />
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={LiveHelpIcon} to="qeta" text="Q&A" />
          <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
